import React from 'react';
import { ORDER_BUTTON_LINK, ORDER_BUTTON_TEXT } from '../../OrderBtn';
import './styles.scss';
import CONTACTS from '../../../json/contacts.json';

import { scrollToSection } from 'common';

export const SECTION_MENU_TITLE = 'Главная';
export const SECTION_NAME = 'Главная';
export const SECTION_LINK = '#main';

const Header = props => {
  return (
    <header
      id="main"
      style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/background.jpg")` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1 className="title">{CONTACTS.title}</h1>
          </div>
          <div className="col-xs-12">
            <p className="text">
              Используем мастерство и традиции барберинга для создания вашего индивидуального стиля
              за короткое время, что очень важно в нашем современном мире
            </p>
          </div>
          <div className="col-xs-12 buttons-block">
            <a
              className="btn-orange"
              rel="noreferrer"
              href={ORDER_BUTTON_LINK}
              target="_blank"
              role="button"
            >
              {ORDER_BUTTON_TEXT}
            </a>
            <a
              className="btn-orange transparent"
              rel="noreferrer"
              href="#portfolio"
              role="button"
              onClick={e => {
                e.preventDefault();
                scrollToSection(e.target.getAttribute('href'));
              }}
            >
              Портфолио
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
