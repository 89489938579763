import React from 'react';
import axios from 'axios';
import Loader from '../../Loader';
import OrderBtn from '../../OrderBtn';
import './styles.scss';
import PRICES from '../../../json/prices.json';
import { useEffect, useState } from 'react';

export const SECTION_MENU_TITLE = 'Прайс';
export const SECTION_NAME = 'Прайс-лист';
export const SECTION_LINK = '#prices';

const CURRENCY = 'BYN';

const Prices = () => {
  const [prices, setPrices] = useState(PRICES);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get('/json/prices.json')
      .then(({ data }) => {
        setPrices(data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getPrices = prices => {
    return prices
      .map((price, index) =>
        prices.length > 1 && index === prices.length - 1
          ? `<span class="price highlighted">${encodeURIComponent(price)} ${CURRENCY}</span>`
          : `<span class="price">${encodeURIComponent(price)} ${CURRENCY}</span>`
      )
      .join(' | ');
  };

  return (
    <div className="section black" id="prices">
      {loading && <Loader />}
      <div>
        <h3 className="section-title">{SECTION_NAME}</h3>
        <h3 className="section-subtitle">Барбер | Топ Барбер</h3>
        <div className="services-wrapper">
          <div className="main-services">
            <div className="services">
              {prices.main.map(item => (
                <div className="service" key={item.title}>
                  <div className="name">{item.title}</div>
                  <div className="dots"></div>
                  <div
                    className="prices"
                    dangerouslySetInnerHTML={{ __html: getPrices(item.prices) }}
                  />
                </div>
              ))}
            </div>

            <div className="services">
              <h4 className="services-subtitle">Дополнительно</h4>
              {prices.additional.map(item => (
                <div className="service" key={item.title}>
                  <div className="name">{item.title}</div>
                  <div className="dots"></div>
                  <div
                    className="prices"
                    dangerouslySetInnerHTML={{ __html: getPrices(item.prices) }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="services-profit-block">
            <h4 className="services-subtitle">Выгодно</h4>
            <h5 className="services-subtitle">
              Барбер | <span className="highlighted">Топ Барбер</span>
            </h5>
            <div className="services">
              {prices.profit.map(item => (
                <div className="service" key={item.title}>
                  <div className="name">{item.title}</div>
                  <div
                    className="prices"
                    dangerouslySetInnerHTML={{ __html: getPrices(item.prices) }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <OrderBtn />
      </div>
    </div>
  );
};

export default Prices;
