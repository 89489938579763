import React from 'react';
import axios from 'axios';
import Loader from '../../Loader';
import OrderBtn from '../../OrderBtn';
import './styles.scss';
import REVIEWS from '../../../json/reviews.json';
import ArrowIcon from '../../../svg/arrow.svg';
import { useEffect, useState } from 'react';

export const SECTION_MENU_TITLE = 'Отзывы';
export const SECTION_NAME = 'Отзывы';
export const SECTION_LINK = '#reviews';

const PAGE_SIZE = 4;

const Reviews = () => {
  const [reviews, setReviews] = useState(REVIEWS);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const pagesCount = Math.ceil(reviews.length / PAGE_SIZE);

  useEffect(() => {
    setLoading(true);
    axios
      .get('/json/reviews.json')
      .then(({ data }) => {
        setReviews(data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handlePrevPage = () => {
    changePage(page > 0 ? page - 1 : pagesCount - 1);
  };

  const handleNextPage = () => {
    changePage(page < pagesCount - 1 ? page + 1 : 0);
  };

  const changePage = pageNumber => {
    if (pageNumber === page) {
      return;
    }

    if (window.jQuery) {
      window.jQuery('#reviews .reviews').fadeOut(300, () => {
        setPage(pageNumber);
      });
    } else {
      setPage(pageNumber);
    }

    if (window.jQuery) {
      window.jQuery('#reviews .reviews');
      window.jQuery('#reviews .reviews').fadeIn(300);
    }
  };

  return (
    <div className="section black" id="reviews">
      {loading && <Loader />}
      <div className="container">
        <h3 className="section-title">{SECTION_NAME}</h3>
        <div className="reviews-wrapper">
          <button
            className="arrow arrow-left"
            style={{ backgroundImage: `url(${ArrowIcon})` }}
            onClick={handlePrevPage}
          ></button>
          <div className="reviews">
            {reviews
              .filter((item, index) => index >= page * PAGE_SIZE && index < (page + 1) * PAGE_SIZE)
              .map((item, index) => (
                <div
                  className={`review ${index % 2 === 0 ? 'review-even' : 'review-odd'}`}
                  key={item.image}
                  style={{
                    maxWidth: `calc(50% - ${Math.round((40 * (PAGE_SIZE - 1)) / PAGE_SIZE)}px)`,
                  }}
                >
                  <img src={item.image} alt="Review" />
                </div>
              ))}
          </div>
          <button
            className="arrow arrow-right"
            style={{ backgroundImage: `url(${ArrowIcon})` }}
            onClick={handleNextPage}
          ></button>
          <div className="pagination">
            {Array.from(Array(pagesCount).keys()).map(pageNumber => (
              <button
                className={`dot${page === pageNumber ? ' active' : ''}`}
                onClick={() => changePage(pageNumber)}
                key={pageNumber}
              ></button>
            ))}
          </div>
        </div>
        <OrderBtn />
      </div>
    </div>
  );
};

export default Reviews;
