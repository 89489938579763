import React, { useState, useEffect } from 'react';
import findLast from 'lodash/findLast';
import throttle from 'lodash/throttle';
import { map } from 'lodash';
import PhoneIcon from '../../svg/phone.svg';
import BurgerIcon from '../../svg/burger.svg';

import CONTACTS from '../../json/contacts.json';
import sections from '../sections';

import './styles.scss';
import { scrollToSection, scrollToTop, NAVBAR_HEIGHT } from '../../common';
import { ORDER_BUTTON_LINK, ORDER_BUTTON_TEXT } from '../OrderBtn';

const notInMenuSections = sections.filter(item => !item.menuHidden);

const NavBar = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(0);

  const handleScroll = () => {
    let scrollTop = document.documentElement.scrollTop;

    const menuItems = map(notInMenuSections, (item, index) => {
      const top = window.jQuery(item.link).offset().top - NAVBAR_HEIGHT;

      return {
        top: top < 0 ? 0 : top,
        index,
      };
    });

    if (scrollTop < menuItems[0].top) {
      return setActiveMenuItem(0);
    }

    const lastItem = findLast(menuItems, item => item.top <= scrollTop);

    if (!lastItem) {
      return false;
    }

    setActiveMenuItem(lastItem.index);
  };

  const throttleScroll = throttle(handleScroll, 200);

  useEffect(() => {
    window.addEventListener('scroll', throttleScroll);

    return () => {
      window.removeEventListener('scroll', throttleScroll);
    };
  }, [throttleScroll]);

  return (
    <nav className="navbar fixed-top navbar-dark bg-dark navbar-expand-lg py-3" id="mainNavbar">
      <div className="container-fluid">
        <button
          className="navbar-toggler order-sm-1 order-lg-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <img src={BurgerIcon} alt="mobile-menu-icon" />
        </button>
        <a
          className="navbar-brand order-sm-2 order-lg-0"
          href="/"
          onClick={e => {
            e.preventDefault();
            scrollToTop();
          }}
        >
          <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="logo" />
          <span>
            <b>{CONTACTS.title.toLocaleUpperCase().split(' ')[0]}</b>&nbsp;
            {CONTACTS.title.toLocaleUpperCase().split(' ')[1]}
          </span>
        </a>
        <div className="d-flex order-sm-3 order-lg-2">
          <a
            className="btn-orange d-none d-lg-flex"
            rel="noreferrer"
            href={ORDER_BUTTON_LINK}
            target="_blank"
            role="button"
          >
            {ORDER_BUTTON_TEXT}
          </a>
          <a href={`tel:${CONTACTS.phone}`} className="btn-orange square">
            <img src={PhoneIcon} alt="Phone icon" />
          </a>
        </div>
        <div className="collapse navbar-collapse order-sm-4 order-lg-1" id="navbarNav">
          <ul className="navbar-nav">
            {notInMenuSections.map((item, index) => {
              const addProps = {};
              const classes = ['nav-link'];
              if (activeMenuItem === index) {
                classes.push('active');
              }
              if (item.addClass) {
                classes.push(item.addClass);
              }
              if (item.newWindow) {
                addProps.rel = 'noreferrer';
                addProps.target = '_blank';
              }
              return (
                <li className="nav-item" key={index}>
                  <a
                    className={classes.join(' ')}
                    aria-current="page"
                    href={item.link}
                    onClick={e => {
                      e.preventDefault();

                      if (window.jQuery) {
                        window.jQuery('#navbarNav').collapse('hide');
                      }

                      if (!item.newWindow) {
                        setActiveMenuItem(index);
                        scrollToSection(e.target.getAttribute('href'));
                      }
                    }}
                    {...addProps}
                  >
                    {item.title}
                  </a>
                </li>
              );
            })}
            <li className="nav-item order-btn">
              <a
                className="btn-orange"
                rel="noreferrer"
                href={ORDER_BUTTON_LINK}
                target="_blank"
                role="button"
              >
                {ORDER_BUTTON_TEXT}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
