import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Loader from '../../Loader';
import './styles.scss';
import PORTFOLIO_ITEMS from '../../../json/portfolio.json';
import CONTACTS from '../../../json/contacts.json';
import InstagramRounded from '../../../svg/instagram-rounded.svg';

export const SECTION_MENU_TITLE = 'Портфолио';
export const SECTION_NAME = 'Наши работы';
export const SECTION_LINK = '#portfolio';

const Portfolio = () => {
  const [portfolio, setPortfolio] = useState(PORTFOLIO_ITEMS);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get('/json/portfolio.json')
      .then(({ data }) => {
        setPortfolio(data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        if (window.lightbox) {
          window.lightbox.option({
            wrapAround: true,
            alwaysShowNavOnTouchDevices: true,
            albumLabel: 'Фото %1 из %2',
          });
        }
      });
  }, []);

  return (
    <div className="section gray" id="portfolio">
      <div className="container">
        {loading && <Loader />}
        <h3 className="section-title">{SECTION_NAME}</h3>
        <div className="row portfolio-items">
          {portfolio.map((item, index) => {
            const alt = item.alt ? item.alt : `Стрижка ${index + 1} из портфолио`;
            const title = item.title ? item.title : `Стрижка ${index + 1} из портфолио`;
            return (
              <div
                className={`col-6 col-sm-4 col-md-4${
                  index === portfolio.length - 1 && index % 2 === 0 ? ' d-none d-sm-block' : ''
                }`}
                key={item.image}
              >
                <a
                  href={process.env.PUBLIC_URL + item.image}
                  data-lightbox={SECTION_NAME}
                  data-title={title}
                >
                  <img
                    src={process.env.PUBLIC_URL + item.thumbnail}
                    className="img-fluid"
                    alt={alt}
                  />
                </a>
              </div>
            );
          })}
        </div>
        <div className="row insta-link">
          <a href={CONTACTS.link_instagram} target="_blank" className="" rel="noreferrer">
            Больше наших работ в инстаграм
            <span
              className="insta-link-img"
              style={{
                '-webkit-mask': `url(${InstagramRounded}) no-repeat center`,
                mask: `url(${InstagramRounded}) no-repeat center`,
              }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
