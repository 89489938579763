import React from 'react';

import './styles.scss';

export const ORDER_BUTTON_LINK = 'https://beauty.dikidi.net/#widget=75675';
export const ORDER_BUTTON_TEXT = 'Записаться онлайн';

const OrderBtn = ({ link = ORDER_BUTTON_LINK, centered = false }) => {
  return (
    <div className="row">
      <div className="col text-center">
        <a
          className="btn-orange centered"
          rel="noreferrer"
          href={link}
          target="_blank"
          role="button"
        >
          {ORDER_BUTTON_TEXT}
        </a>
      </div>
    </div>
  );
};

export default OrderBtn;
