import React, { useRef, useEffect } from 'react';
import './styles.scss';
import InstagramIcon from '../../../svg/instagram.svg';
import TelegramIcon from '../../../svg/telegram.svg';
import CONTACTS from '../../../json/contacts.json';
import { formatPhone } from '../../../common';

export const SECTION_MENU_TITLE = 'Контакты';
export const SECTION_NAME = 'Контакты';
export const SECTION_LINK = '#contacts';

const Contacts = props => {
  const mapRef = useRef(null);

  useEffect(() => {
    const externalScript = document.createElement('script');
    externalScript.src =
      'https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A099e44d34b7cc4526e6a3ecf5280e1e127323d566ef52f4bd248744e6f99ee27&amp;width=560&amp;height=530&amp;lang=ru_RU&amp;scroll=true';
    externalScript.async = true;
    externalScript.charset = 'utf-8';
    externalScript.type = 'text/javascript';
    mapRef.current.append(externalScript);
  }, []);

  return (
    <div id="contacts">
      <div className="container">
        <div className="address-section">
          <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="logo" className="logo" />
          <span className="contacts-title">{CONTACTS.title}</span>
          <div className="social-links">
            <a href={CONTACTS.link_instagram} target="_blank" rel="noreferrer">
              <img src={InstagramIcon} alt="Instagram icon" />
            </a>
            <a href={CONTACTS.link_telegram} target="_blank" rel="noreferrer">
              <img src={TelegramIcon} alt="Telegram icon" />
            </a>
          </div>
          <p className="h5">Адрес</p>
          <p>{CONTACTS.address}</p>
          <p className="h5">Контакты</p>
          <p>
            <a href={`tel:${CONTACTS.phone}`} className="no-decoration">
              {formatPhone(CONTACTS.phone)}
            </a>
          </p>
          <p className="h5">Режим работы</p>
          <p>{CONTACTS.schedule.time}</p>
          <p>{CONTACTS.schedule.days}</p>
        </div>
        <div id="map" ref={mapRef}></div>
      </div>
    </div>
  );
};

export default Contacts;
