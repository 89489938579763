import React, { useEffect, useState } from 'react';
import Snowfall from 'react-snowfall';

function Snowflakes() {
  const [images, setImages] = useState([]);

  function loadSnowFlakes() {
    const snowflake1 = document.createElement('img');
    snowflake1.src = `${process.env.PUBLIC_URL}/images/new-year/snowflakes/snow1.png`;
    const snowflake2 = document.createElement('img');
    snowflake2.src = `${process.env.PUBLIC_URL}/images/new-year/snowflakes/snow2.png`;
    const snowflake3 = document.createElement('img');
    snowflake2.src = `${process.env.PUBLIC_URL}/images/new-year/snowflakes/snow3.png`;
    setImages([snowflake1, snowflake2, snowflake3]);
  }

  useEffect(() => {
    loadSnowFlakes();
  }, []);

  return (
    <Snowfall
      snowflakeCount={120}
      speed={[0, 1.5]}
      wind={[-0.5, 0.5]}
      rotationSpeed={[-1, 1]}
      radius={[5, 18]}
      color="#fff"
      images={images}
      style={{
        zIndex: 1031,
        position: 'fixed',
      }}
    />
  );
}

export default Snowflakes;
