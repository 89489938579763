export const formatPhone = phone => phone.replace(/\D+/g, '').replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5')

export const NAVBAR_HEIGHT = 72

const cleanUrl = () => {
    const uri = window.location.toString();

    if (uri.indexOf("#") > 0) {
        const clean_uri = uri.substring(0, uri.indexOf("#"))

        window.history.replaceState({}, document.title, clean_uri)
    }
}

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
}

export const scrollToSection = (sectionId) => {
    const section = window.jQuery(sectionId)

    if (!section || !window.jQuery) {
        return false
    }

    const top = section.offset().top - NAVBAR_HEIGHT

    window.scrollTo({
        top: top < 0 ? 0 : top,
        left: 0,
        behavior: 'smooth'
    })
}

export const onLoadFunc = () => {
    const anchor = window.location.hash
    if (anchor) {
        cleanUrl()
        scrollToSection(anchor)
    }
}