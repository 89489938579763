import Header, { SECTION_MENU_TITLE as HEADER_SECTION_NAME, SECTION_LINK as HEADER_SECTION_LINK } from './Header'
import Portfolio, { SECTION_MENU_TITLE as PORTFOLIO_SECTION_NAME, SECTION_LINK as PORTFOLIO_SECTION_LINK } from './Portfolio'
import Prices, { SECTION_MENU_TITLE as PRICES_SECTION_NAME, SECTION_LINK as PRICES_SECTION_LINK } from './Prices'
import Team, { SECTION_MENU_TITLE as TEAM_SECTION_NAME, SECTION_LINK as TEAM_SECTION_LINK } from './Team'
import Reviews, { SECTION_MENU_TITLE as REVIEWS_SECTION_NAME, SECTION_LINK as REVIEWS_SECTION_LINK } from './Reviews'
import Contacts, { SECTION_MENU_TITLE as CONTACTS_SECTION_NAME, SECTION_LINK as CONTACTS_SECTION_LINK } from './Contacts'
// import Map, { SECTION_MENU_TITLE as MAP_SECTION_NAME, SECTION_LINK as MAP_SECTION_LINK } from './Map'

const pages = [
    {
        title: HEADER_SECTION_NAME,
        link: HEADER_SECTION_LINK,
        Component: Header,
    },
    {
        title: PORTFOLIO_SECTION_NAME,
        link: PORTFOLIO_SECTION_LINK,
        Component: Portfolio
    },
    {
        title: PRICES_SECTION_NAME,
        link: PRICES_SECTION_LINK,
        Component: Prices
    },
    {
        title: TEAM_SECTION_NAME,
        link: TEAM_SECTION_LINK,
        Component: Team,
        menuHidden: true
    },
    {
        title: REVIEWS_SECTION_NAME,
        link: REVIEWS_SECTION_LINK,
        Component: Reviews,
        menuHidden: true
    },
    {
        title: CONTACTS_SECTION_NAME,
        link: CONTACTS_SECTION_LINK,
        Component: Contacts
    },
    /*{
        title: MAP_SECTION_NAME,
        link: MAP_SECTION_LINK,
        Component: Map,
        menuHidden: true
    }*/
]

export default pages