import React, { useEffect } from 'react'
import { onLoadFunc } from './common'
import NavBar from './components/NavBar'
import sections from './components/sections'
import Snowflakes from './components/Snowflakes'

export const WITH_SNOWFLAKES = false

function App() {
  useEffect(() => {
    onLoadFunc()
  }, [])

  return (
    <>
      {WITH_SNOWFLAKES && <Snowflakes />}
      <NavBar />
      {
        sections.map(({ Component, link }) => (<Component key={link} />))
      }
    </>
  );
}

export default App;